.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

.modal__content {
    background-color: white;
    width: 45vw;
    height: 85vh;
    padding: 30px;
}
