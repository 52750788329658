@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

p {
  margin: 0;
  padding: 0;
}
