.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.root .form {
  background: #fafafa;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  min-height: 200px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
  overflow: hidden;
}
.root .title {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 150px;
}
.root .title h2 {
  font-weight: normal;
}
.root .button {
  background-color: #fff !important;
}
.root .brusnikaIcon {
  text-align: center;
}
.root .googleIcon {
  height: 24px;
}
.root .error {
  min-height: 24px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}
