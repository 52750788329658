.main {
    overflow-y: scroll;
}

.main h1 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 100;
}

.appBar {
    padding: 0 24px;
    box-shadow: none!important;
    display: flex!important;
    flex-direction: row!important;
    justify-content: space-between;
    align-items: center;
}

.appBar .name {
    display: flex;
    align-items: center;
}

.appBar img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-right: 10px;
}

.myProfileButton {
}

.content {
    padding: 24px;
    min-height: 100vh;
}

.searchInput {
    padding: 8px 0;
    width: 100%;
    box-sizing: border-box;
    font-size: 24px!important;
}

.tableUsers {
    margin-top: 16px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: cover;
    margin: 6px 0;
}

.noResult {
    text-align: center;
    font-size: 32px;
    padding: 12vh;
    color: #e0e0e0;
}

.user-table__row {
    cursor: pointer;
}

.invalid {
    border-left: 4px solid #ef3a24;
}

.service {
    border-left: 4px solid #c0d2de;
    background: #ebf3f7;
}

.user-table__row:hover {
    background-color: #f7f7f7;
}

.user-table__row img {
    width: 20px;
    height: 20px;
}

.s-alert-wrapper {
    position: absolute;
    z-index: 9999;
}

.search__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search__organization {
    width: 800px;
    margin-top: 20px;
    margin-left: 40px;
}

.search__invalid-label {
    font-size: 12px;
    color: #bbbbbb;
    padding-left: 40px;
    width: 120px;
}

