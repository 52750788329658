.md__root {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.md__body {
    overflow-y: scroll;
    height: 100%;
}

.md__service {
    margin-top: 15px;
}

.md__inline {
    display: flex;
    justify-content: space-between;
}

.md__inline * {
    width: 100%;
    margin-right: 25px!important;
}

.md__head {
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 120px;
    box-shadow: 0 5px 10px -10px #000;
    position: relative;
}

.md__status {
    text-transform: lowercase;
    font-size: 14px;
    color: #989898;
}

.md__head img {
    width: 65px;
    height: 65px;
    background-color: #bbb;
    margin-right: 15px;
    border-radius: 100%;
    object-fit: cover;
    cursor: pointer;
}

.md__head img:hover {
    opacity: 0.5;
}

.md__head h1 {
    margin: 2px 0;
    max-width: 90%;
    font-size: 24px;
}

.md__form {
    background: #fbfbfb;
    padding: 20px 0 20px 20px;
    margin-top: 15px;
    box-shadow: 0 2px 5px -4px #000;
    border-left: 2px solid #f1f1f1;
}

.md__title {
    color: #6d78a5;
    margin-bottom: 20px;
    font-size: 16px;
}

.md__text-field {
    width: 97%;
}

.md__text-field:not(:last-child) {
    margin-bottom: 25px!important;
}

.md__button {
    width: 150px;
    height: 40px;
}

.md__addButtonWrapper {
    width: 97%;
    display: flex;
    justify-content: flex-end;
    margin: -10px 0;
}

.md__addButton {
    color: #989898!important;
    font-size: 10px!important;
}

.md__error {
    color: red;
    font-size: 12px;
}

.md__moderator-label {
    font-size: 13px;
    color: #848484;
}

.md__user-label {
    background: #51bb82;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 5px;
    color: #fff;
    margin-right: 8px;
}

.md__copy-label {
    background: #8540bb;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

.md__copy-label:hover {
    background: #947fbb;
}

.md__copy-label:active {
    background: #662cbb;
}

.selector__organizations {
    width: 97%;
    margin-bottom: 20px!important;
}
