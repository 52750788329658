@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.md__root {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.md__body {
    overflow-y: scroll;
    height: 100%;
}

.md__service {
    margin-top: 15px;
}

.md__inline {
    display: flex;
    justify-content: space-between;
}

.md__inline * {
    width: 100%;
    margin-right: 25px!important;
}

.md__head {
    display: flex;
    flex-direction: row;
    height: 120px;
    box-shadow: 0 5px 10px -10px #000;
    position: relative;
}

.md__status {
    text-transform: lowercase;
    font-size: 14px;
    color: #989898;
}

.md__head img {
    width: 65px;
    height: 65px;
    background-color: #bbb;
    margin-right: 15px;
    border-radius: 100%;
    object-fit: cover;
    cursor: pointer;
}

.md__head img:hover {
    opacity: 0.5;
}

.md__head h1 {
    margin: 2px 0;
    max-width: 90%;
    font-size: 24px;
}

.md__form {
    background: #fbfbfb;
    padding: 20px 0 20px 20px;
    margin-top: 15px;
    box-shadow: 0 2px 5px -4px #000;
    border-left: 2px solid #f1f1f1;
}

.md__title {
    color: #6d78a5;
    margin-bottom: 20px;
    font-size: 16px;
}

.md__text-field {
    width: 97%;
}

.md__text-field:not(:last-child) {
    margin-bottom: 25px!important;
}

.md__button {
    width: 150px;
    height: 40px;
}

.md__addButtonWrapper {
    width: 97%;
    display: flex;
    justify-content: flex-end;
    margin: -10px 0;
}

.md__addButton {
    color: #989898!important;
    font-size: 10px!important;
}

.md__error {
    color: red;
    font-size: 12px;
}

.md__moderator-label {
    font-size: 13px;
    color: #848484;
}

.md__user-label {
    background: #51bb82;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 5px;
    color: #fff;
    margin-right: 8px;
}

.md__copy-label {
    background: #8540bb;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

.md__copy-label:hover {
    background: #947fbb;
}

.md__copy-label:active {
    background: #662cbb;
}

.selector__organizations {
    width: 97%;
    margin-bottom: 20px!important;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

.modal__content {
    background-color: white;
    width: 45vw;
    height: 85vh;
    padding: 30px;
}

.main {
    overflow-y: scroll;
}

.main h1 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 100;
}

.appBar {
    padding: 0 24px;
    box-shadow: none!important;
    display: flex!important;
    flex-direction: row!important;
    justify-content: space-between;
    align-items: center;
}

.appBar .name {
    display: flex;
    align-items: center;
}

.appBar img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-right: 10px;
}

.myProfileButton {
}

.content {
    padding: 24px;
    min-height: 100vh;
}

.searchInput {
    padding: 8px 0;
    width: 100%;
    box-sizing: border-box;
    font-size: 24px!important;
}

.tableUsers {
    margin-top: 16px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: cover;
    margin: 6px 0;
}

.noResult {
    text-align: center;
    font-size: 32px;
    padding: 12vh;
    color: #e0e0e0;
}

.user-table__row {
    cursor: pointer;
}

.invalid {
    border-left: 4px solid #ef3a24;
}

.service {
    border-left: 4px solid #c0d2de;
    background: #ebf3f7;
}

.user-table__row:hover {
    background-color: #f7f7f7;
}

.user-table__row img {
    width: 20px;
    height: 20px;
}

.s-alert-wrapper {
    position: absolute;
    z-index: 9999;
}

.search__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search__organization {
    width: 800px;
    margin-top: 20px;
    margin-left: 40px;
}

.search__invalid-label {
    font-size: 12px;
    color: #bbbbbb;
    padding-left: 40px;
    width: 120px;
}


.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.root .form {
  background: #fafafa;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  min-height: 200px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
  overflow: hidden;
}
.root .title {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 150px;
}
.root .title h2 {
  font-weight: normal;
}
.root .button {
  background-color: #fff !important;
}
.root .brusnikaIcon {
  text-align: center;
}
.root .googleIcon {
  height: 24px;
}
.root .error {
  min-height: 24px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

p {
  margin: 0;
  padding: 0;
}

